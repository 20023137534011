<template>
  <div
    ref="modalPhieuXacNhanDieuTri"
    class="modal fade"
    id="modalPhieuXacNhanDieuTri"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalPhieuXacNhanDieuTriLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>GIẤY XÁC NHẬN ĐIỀU TRỊ</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                <label for>{{ person.name }}</label>
              </div>
            </div>

            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Giới tính:</label>
              <label for>{{ person.gender === 1 ? 'Nam' : 'Nữ' }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div>
                <label class="fs-14 fw-700 w-44">Ngày sinh:</label>
                <label for>{{ person.birthday && moment(person.birthday).format('DD/MM/YYYY') }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Mã bệnh nhân:</label>
              <label for>{{ person.pid }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
                <label for>{{ addressPerson }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Đang điều trị tại khoa:</label>
              <label for>{{ $globalClinic.name }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Số thẻ bảo hiểm y tế:</label>
                <label for>{{ person.insurance_number || '' }}</label>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="fs-14 fw-700 w-44">Chẩn đoán bệnh:</div>
            <EditorInput
              id="diagnostic-xndt"
              :prop_text="form.diagnostic"
              @onChangeContent="(content) => form.diagnostic = content"
            />
            <!-- <label for>{{ medicalRecordMain && medicalRecordMain.treat_dept_main_name }}</label> -->
          </div>
          <div class="mb-3">
            <div class="fs-14 fw-700 w-44">Hướng điều trị:</div>
            <EditorInput
              id="treatment-xndt"
              :prop_text="form.treatment"
              @onChangeContent="(content) => form.treatment = content"
            />
            <!-- <label for>{{ medicalRecordMain && medicalRecordMain.treat_dept_main_name }}</label> -->
          </div>
          <div class="flex items-center gap-4">
            <div class="col-3 float-left">
              <label class="fs-14 fw-700">Bác sĩ điều trị:</label>
              <el-select
                v-model="form.doctor_id"
                filterable
                class="w-full"
                placeholder="Chọn bác sĩ"
              >
                <el-option
                  v-for="item in doctors"
                  :key="item.id + 'td'"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="col-3 float-right">
              <label class="fs-14 fw-700">Chọn ngày xác nhận:</label>
              <input v-model="form.confirmation_date" type="date" class="form--input" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-loading="loading"
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
          >Lưu phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import EditorInput from '@/components/EditorInput.vue'
import { mapState } from 'vuex'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'ModalXacNhanDieuTri',
  components: { EditorInput },
  props: ['type', 'treatmentConfData'],
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      form: {
        doctor_id: '',
        diagnostic: '',
        treatment: '',
        confirmation_date: window.moment().format('YYYY-MM-DD')
      },
      defaultForm: {
        doctor_id: '',
        diagnostic: '',
        treatment: '',
        confirmation_date: window.moment().format('YYYY-MM-DD')
      },
      doctors: null,
      loading: false
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
      },
      deep: true,
      immediate: true
    },
    treatmentConfData () {
      this.handleMapUpdateData()
    },
    medicalRecordMain () {
      if (this.type === 'create') {
        let self = this
        self.form.diagnostic = self.medicalRecordMain.treat_dept_main_name
        self.form.treatment = self.medicalRecordMain.treatment_direction
      }
    }
  },
  mounted () {
    this.getDoctors()
    this.handleMapUpdateData()

    if (this.type === 'create') {
      let self = this
      const currentDoctorId = self.$user.doctor.id
      self.form.doctor_id = currentDoctorId
      self.form.diagnostic = self.medicalRecordMain.treat_dept_main_name
      self.form.treatment = self.medicalRecordMain.treatment_direction
    }
  },
  computed: {
    ...mapState({
      emrData: state => state.emrData,
      person: state => state.person
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain
    }),
    addressPerson () {
      return this.getAddress(this.medicalRecordMain || this.person)
    }
  },

  methods: {
    handleMapUpdateData () {
      if (this.type === 'update' && this.treatmentConfData) {
        this.form = {
          ...this.form,
          doctor_id: this.treatmentConfData.treat_doctor_id,
          diagnostic:
            this.treatmentConfData.diagnostic ||
            this.medicalRecordMain.treat_dept_main_name,
          treatment:
            this.treatmentConfData.treatment ||
            this.medicalRecordMain.treatment_direction,
          confirmation_date: this.treatmentConfData.confirmation_date
            ? window
              .moment(this.treatmentConfData.confirmation_date)
              .format('YYYY-MM-DD')
            : window.moment().format('YYYY-MM-DD')
        }
      }
    },
    formatDate (time) {
      if (time) {
        moment(time).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    onSubmit () {
      if (!this.form.doctor_id) {
        alert('Vui lòng nhập thông tin bác sĩ')
        return
      }

      if (this.type === 'create') {
        this.$emit('onCreate', this.form)
        this.form = { ...this.defaultForm, doctor_id: self.$user.doctor.id }
        window.$('button#closeModal').trigger('click')
      } else {
        this.updateTreatmentConfirmation()
      }
    },
    async updateTreatmentConfirmation () {
      this.loading = true
      const id = this.$route.query.vId
      const params = {
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id,
        diagnostic: this.form.diagnostic,
        treatment: this.form.treatment,
        workspace_id: this.$globalClinic.id,
        treat_doctor_id: this.form.doctor_id,
        user_id: this.$user.id,
        confirmation_date: this.form.confirmation_date
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateTreatmentConfirmation(id, params)
        .then(r => {
          this.$emit('onUpdate')
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
      this.doctors = res.data?.data
    },
    openModal (show) {
      window.$('#modalPhieuXacNhanDieuTri').modal(show ? 'show' : 'hide')
      let self = this
      self.$nextTick(function () {
        this.form = { ...this.defaultForm, doctor_id: self.$user.doctor.id }
      })
    }
  }
}
</script>
<style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
</style>
