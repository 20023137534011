<template>
  <div
    ref="modalPhieuPhauThuat"
    class="modal fade"
    id="modalPhieuPhauThuat"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalPhieuPhauThuatLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>GIẤY HẸN KHÁM LẠI</span>
          </div>
          <button
            @click="closeModal"
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                <label for>{{ person.name }}</label>
              </div>
            </div>

            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Giới tính:</label>
              <label for>{{ person.gender === 1 ? "Nam" : "Nữ" }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Ngày sinh:</label>
                <label for>
                  {{
                  person.birthday &&
                  moment(person.birthday).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Số điện thoại:</label>
              <label for>{{ person.phone }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
                <label for>{{ person.address }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Số thẻ bảo hiểm y tế:</label>
                <label for>{{ person.insurance_number || "" }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Hạn sử dụng:</label>
                <label for>
                  {{
                  person.insurance_expire &&
                  moment(person.insurance_expire).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class="h-10">
                <label class="fs-14 fw-700 w-44">Ngày vào viện:</label>
              </div>
              <div class="col-md-7 h-10" style="position: relative">
                <input
                  class="form--input"
                  type="date"
                  v-model="form.reception_at"
                  style="position: absolute; transform: translateY(-10px)"
                />
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <div class="h-10">
                <label class="fs-14 fw-700 w-44">Ngày ra viện:</label>
              </div>
              <div class="col-md-7 h-10" style="position: relative">
                <input
                  class="form--input"
                  type="date"
                  v-model="form.discharge_at"
                  style="position: absolute; transform: translateY(-10px)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Chẩn đoán:</label>
                <label for>
                  {{
                  medicalRecordMain && medicalRecordMain.treat_dept_main_name
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Bệnh kèm theo:</label>
                <label for>
                  {{
                  medicalRecordMain &&
                  medicalRecordMain.treat_dept_including_name
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700 w-44">Lời dặn:</label>
                <b-form-textarea
                  v-model="form.content"
                  placeholder="Lời dặn cho bệnh nhân..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-3">
            <div class="row mb-3">
              <div class>
                <label class="fs-14 fw-700">Chọn ngày khám:</label>
                <input class="form--input" type="date" v-model="form.examination_date" />
              </div>
            </div>
            <div class="row">
              <div class>
                <label class="fs-14 fw-700">Đại diện cơ sở khám chữa bệnh:</label>
                <el-select
                  v-model="form.WSRepresentativeID"
                  filterable
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                  clearable
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-3">
            <div class="row">
              <div class>
                <label class="fs-14 fw-700">Bác sĩ khám bệnh:</label>
                <el-select
                  v-model="form.doctor_id"
                  filterable
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                  @change="changeDoctor"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class>
                <label class="fs-14 fw-700">Phòng khám:</label>
                <el-select
                  v-model="form.cr_id"
                  filterable
                  class="w-full"
                  placeholder="Chọn phòng khám"
                  @change="timeSlotStartTime = null"
                >
                  <el-option
                    v-for="item in consultingRooms"
                    :key="item.id + 'td'"
                    :label="item.cr_info.name"
                    :value="item.consulting_room_id"
                  />
                </el-select>
              </div>
            </div>
            <div class="row mb-3">
              <div class>
                <label class="fs-14 fw-700">Chọn ngày tái khám:</label>
                <input
                  v-model="form.re_examination_time"
                  :min="disabledPast"
                  type="date"
                  class="form--input"
                  @input="timeSlotStartTime = null"
                />
                <!-- <input v-model="form.re_examination_time" type="datetime-local" class="form--input" /> -->
              </div>
              <div class="mt-3">
                <div class="flex">
                  <label class="fs-14 fw-700">Chọn thời gian khám</label>
                  <el-checkbox class="ml-3" v-model="showTimeSlot" size="large" />
                </div>
                <el-select
                  v-if="showTimeSlot"
                  v-model="timeSlotStartTime"
                  class="w-full"
                  placeholder="Chọn thời gian khám"
                >
                  <el-option
                    v-for="item in timeslots"
                    :key="item.Start + 'td'"
                    :label="item.Start"
                    :value="item.Start"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { USE_ROOM_PRICE_CR } from '../../../utils/constants'
export default {
  name: 'ModalGiayHenKhamLai',
  components: { CkeditorNormal },
  props: ['type'],
  data () {
    const defaultForm = {
      examination_date: moment().format('YYYY-MM-DD'),
      doctor_id: '',
      re_examination_time: '',
      timeSlot: '',
      discharge_at: '',
      reception_at: '',
      content: '',
      WSRepresentativeID: ''
    }

    return {
      showTimeSlot: false,
      arrFiles: [],
      file: null,
      dateSelected: null,
      timeslots: [],
      timeSlotStartTime: null,
      consultingRooms: [],
      imageDefault:
        'https://phutungnhapkhauchinhhang.com/wp-content/uploads/2020/06/default-thumbnail.jpg',
      form: {
        ...defaultForm,
        cr_id: ''
      },
      defaultForm,
      doctors: null,
      loading: false,
      imageSrc1: null,
      imageSrc2: null,
      imageSrc3: null,
      imageSrc4: null,
      imageSrc5: null,
      foundSlot: {
        Start: '',
        End: ''
      }
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
      },
      deep: true,
      immediate: true
    },
    computedProperty () {
      if (
        this.form.cr_id &&
        this.form.re_examination_time &&
        this.showTimeSlot
      ) {
        this.getTimeSlots(
          this.form.cr_id,
          this.form.doctor_id,
          this.form.re_examination_time
        )
      }
    }
  },
  mounted () {
    this.getDoctors()
    if (this.type === 'update') {
      this.showReExamination(true)
    }
    if (this.type === 'create') {
      let self = this
      const currentDoctorId = self.$user.doctor.id
      self.form.doctor_id = currentDoctorId
      this.getConsultingRooms(currentDoctorId)
      self.$nextTick(function () {
        window.$('#modalPhieuPhauThuat').on('shown.bs.modal', function (e) {
          // self.form.before_diagnose =
          //   self.medicalRecordMain?.treat_dept_main_name || ''
          if (self.consultingRooms.length) {
            self.form.cr_id = self.consultingRooms[0].consulting_room_id
          } else {
            self.form.cr_id = ''
          }
          self.form = {
            reception_at: self.medicalRecordMain.reception_at
              ? moment(self.medicalRecordMain.reception_at).format('YYYY-MM-DD')
              : '',
            discharge_at: self.medicalRecordMain.discharge_at
              ? moment(self.medicalRecordMain.discharge_at).format('YYYY-MM-DD')
              : ''
          }
        })

        window.$('#modalPhieuPhauThuat').on('hidden.bs.modal', function (e) {
          self.form.before_diagnose = ''
        })
      })
    }
  },
  computed: {
    computedProperty () {
      return [
        this.form.cr_id,
        this.form.re_examination_time,
        this.showTimeSlot
      ].join()
    },
    ...mapState({
      emrData: state => state.emrData,
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain || {}
    }),
    disabledPast () {
      const currentDate = moment().format('YYYY-MM-DD')
      return currentDate
    }
  },
  methods: {
    changeDoctor () {
      this.form.cr_id = ''
      this.timeslots = []
      this.getConsultingRooms(this.form.doctor_id)
      this.timeSlotStartTime = null
      console.log('change doctor')
    },
    async getTimeSlots (cr_id, doctor_id, date) {
      this.loading = true
      this.timeslot = null
      const params = {
        DoctorID: String(doctor_id),
        Date: date,
        CrID: String(cr_id),
        CurrentTime: moment().format('YYYY-MM-DD HH:mm')
      }
      await this.$rf
        .getRequest('AuthRequest')
        .getTimeSlotByCrID(params)
        .then(r => {
          if (typeof r.data !== 'string') {
            this.timeslots = r.data
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getConsultingRooms (doctorId) {
      this.loading = true
      const params = {
        doctor_id: doctorId,
        clinic_id: this.$globalClinic.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getConsultingRoomsV2(params)
        .then(r => {
          this.consultingRooms = r.data
          if (this.consultingRooms.length) {
            if (this.type === 'create') {
              this.form.cr_id = this.consultingRooms[0].consulting_room_id
            }
          } else {
            this.form.cr_id = ''
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async showReExamination () {
      let id = this.$route.query.vId
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getReExamination(id)
        .then(r => {
          const notes = r.data.notes?.reverse()
          this.form = {
            content: notes.length > 0 ? notes[0]?.content : '',
            re_examination_time:
              r.data.re_examination_time &&
              moment(r.data.re_examination_time)
                .utc()
                .format('YYYY-MM-DD'),
            examination_date:
              r.data.examination_date &&
              moment(r.data.examination_date)
                .utc()
                .format('YYYY-MM-DD'),
            doctor_id: r.data.doctor_id,
            cr_id: r.data.cr_id,
            reception_at: r.data.reception_at
              ? moment(r.data.reception_at)
                .utc()
                .format('YYYY-MM-DD')
              : this.medicalRecordMain.reception_at
                ? moment(this.medicalRecordMain.reception_at)
                  .utc()
                  .format('YYYY-MM-DD')
                : '',
            discharge_at: r.data.discharge_at
              ? moment(r.data.discharge_at)
                .utc()
                .format('YYYY-MM-DD')
              : this.medicalRecordMain.discharge_at
                ? moment(this.medicalRecordMain.discharge_at)
                  .utc()
                  .format('YYYY-MM-DD')
                : '',
            WSRepresentativeID: r.data.ws_representative_id
          }

          this.getConsultingRooms(r.data.doctor_id)
          if (r.data.Appt.start_time) {
            this.showTimeSlot = true
            this.timeSlotStartTime = moment(r.data.Appt.start_time)
              .utc()
              .format('HH:mm')
            this.foundSlot.Start = moment(r.data.Appt.start_time)
              .utc()
              .format('HH:mm')
            this.foundSlot.End = moment(r.data.Appt.end_time)
              .utc()
              .format('HH:mm')

            this.getTimeSlots(
              this.form.cr_id,
              this.form.doctor_id,
              this.form.re_examination_time
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate (time) {
      if (time) {
        moment(time).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    onSubmit () {
      if (!this.form.doctor_id) {
        alert('Vui lòng nhập thông tin bác sĩ')

        return
      }
      if (!this.form.re_examination_time) {
        alert('Vui lòng nhập ngày')
        return
      }
      var slotTimePayload = {}
      if (this.showTimeSlot && this.timeSlotStartTime) {
        var foundSlotCurrent = this.timeslots.find(
          x => x.Start === this.timeSlotStartTime
        )
        const selectedConsultingRoom = this.consultingRooms.find(
          x => x.consulting_room_id === this.form.cr_id
        )
        if (this.type === 'update' && !foundSlotCurrent) {
          foundSlotCurrent = this.foundSlot
        }
        // console.log(selectedConsultingRoom)

        slotTimePayload = {
          SlotDuration: selectedConsultingRoom.cr_info.slot_duration,
          // SlotPrice: selectedConsultingRoom.cr_info.slot_price,
          SlotPrice:
            selectedConsultingRoom.cr_info?.is_using_room_price ===
              USE_ROOM_PRICE_CR.isNotUse &&
            selectedConsultingRoom?.service_local?.local_price
              ? selectedConsultingRoom?.service_local?.local_price
              : selectedConsultingRoom.cr_info?.is_using_room_price.slot_price,
          ReExaminationStartTime: moment(
            this.form.re_examination_time + ' ' + foundSlotCurrent.Start
          ).format('YYYY-MM-DD HH:mm:ss'),
          ReExaminationEndTime: moment(
            this.form.re_examination_time + ' ' + foundSlotCurrent.End
          ).format('YYYY-MM-DD HH:mm:ss'),
          LineNumber: foundSlotCurrent.LineNumber
        }
      }
      const payloadForm = {
        CrId: this.form.cr_id,
        DoctorId: this.form.doctor_id,
        ExaminationDate:
          this.form.examination_date &&
          moment(this.form.examination_date).format('YYYY-MM-DD HH:mm:ss'),
        ReExaminationDate: this.showTimeSlot
          ? null
          : moment(this.form.re_examination_time).format('YYYY-MM-DD HH:mm:ss'),
        Method: '1',
        DischargeAt: this.form?.discharge_at || null,
        ReceptionAt: this.form?.reception_at || null,
        Content: this.form?.content || '',
        WSRepresentativeID: this.form?.WSRepresentativeID || '',
        ...slotTimePayload
      }

      if (this.type === 'create') {
        this.$emit('onCreate', payloadForm)
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      } else {
        this.updateReExamination(payloadForm)
        // this.closeModal()
      }
    },
    async updateReExamination (event) {
      this.loading = true
      const id = this.$route.query.vId
      const params = {
        ...event,
        MedicalRecordId: id,
        PersonId: this.person.id,
        WorkspaceId: this.$globalClinic.id,
        OrgId: this.$globalClinic.organization
          ? this.$globalClinic.organization.id
          : null
      }
      console.log(params)
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateReExamination(id, formData)
        .then(r => {
          this.$emit('onUpdate', this.form)
          this.showReExamination()
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      const params = {
        page: 1,
        limit: 1000
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, params)
      this.doctors = res.data?.data
    },
    closeModal () {
      if (this.type === 'create') {
        this.form.re_examination_time = ''
        this.form.content = ''
        this.timeslots = []
        this.timeSlot = null
        this.showTimeSlot = false
      }
    },
    openModal (show) {
      window.$('#modalPhieuPhauThuat').modal(show ? 'show' : 'hide')
      let self = this
      self.$nextTick(function () {
        this.form = this.defaultForm
        window.$('#modalPhieuPhauThuat').on('shown.bs.modal', function (e) {
          // self.form.before_diagnose =
          //   self.medicalRecordMain?.treat_dept_main_name || ''
        })

        window.$('#modalPhieuPhauThuat').on('hidden.bs.modal', function (e) {
          self.form.before_diagnose = ''
          this.showTimeSlot = false
          console.log('Close modal')
        })
      })
    }
  }
}
</script>
<style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
</style>
